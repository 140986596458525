.App {
  display: flex;
  flex-direction: row;
}

.Combinations {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.AddBtn {
  font-size: 1.15rem;
}

.Pairs {
  display: flex;
  flex-direction: column;
  flex: 2;
}

.Preview {
  display: flex;
  flex-direction: column;
  flex: 2;
  padding-top: 100px;
  align-items: center;
}
